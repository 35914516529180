// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const ICONS = {
  dashboard: getIcon('eva:pie-chart-2-fill'),
  masterData: getIcon('icon-park-solid:sales-report'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'master data',
        path: PATH_DASHBOARD.masterData.root,
        icon: ICONS.masterData,
        children: [
          { title: 'ledger / party', path: PATH_DASHBOARD.masterData.ledgerParty, permission: 'ledgerManagement' },
          {
            title: 'inventory master',
            path: PATH_DASHBOARD.masterData.inventoryMaster,
            permission: 'inventoryManagement',
          },
          { title: 'service master', path: PATH_DASHBOARD.masterData.serviceMaster, permission: 'sectionManagement' },
        ],
      },
      {
        title: 'sales',
        permission: 'salesManagement',
        path: PATH_DASHBOARD.sales.invoiceList,
        icon: getIcon('icon-park-solid:sales-report'),
      },
      {
        title: 'purchase',
        permission: 'purchaseManagement',
        path: PATH_DASHBOARD.purchase.root,
        icon: getIcon('fa:cart-arrow-down'),
      },
      {
        title: 'receipt',
        permission: 'receiptManagement',
        path: PATH_DASHBOARD.reciept.root,
        icon: getIcon('carbon:piggy-bank'),
      },
      {
        title: 'payment',
        permission: 'paymentManagement',
        path: '/dashboard/payment',
        icon: getIcon('bi:cash-coin'),
      },

      {
        title: 'expense or journal',
        permission: 'expenseManagement',
        path: PATH_DASHBOARD.expenseOrJournal.root,
        icon: getIcon('icon-park-solid:other'),
      },
      {
        title: 'hr and employee',
        permission: 'hrManagement',
        path: '/dashboard/hrAndEmployee',
        icon: getIcon('charm:notes-cross'),
      },
      {
        title: 'tax',
        path: PATH_DASHBOARD.tax.root,
        icon: getIcon('icon-park-solid:table-report'),
        children: [
          { title: 'GST', path: PATH_DASHBOARD.tax.gst, permission: 'gstManagement' },
          { title: 'TDS', path: PATH_DASHBOARD.tax.tds, permission: 'tdsManagement' },
          { title: 'TCS', path: PATH_DASHBOARD.tax.tcs, permission: 'tcsManagement' },
          { title: 'Income tax', path: PATH_DASHBOARD.tax.incomeTax, permission: 'incomeTaxManagement' },
          { title: 'VAT', path: PATH_DASHBOARD.tax.vat, permission: 'vatManagement' },
          { title: 'Duty', path: PATH_DASHBOARD.tax.duty, permission: 'dutyManagement' },
        ],
      },
      {
        title: 'report',
        permission: 'reportManagement',
        path: PATH_DASHBOARD.report.root,
        icon: getIcon('icon-park-solid:table-report'),
        children: [
          { title: 'financial-statement', path: PATH_DASHBOARD.report.financial},
        ],
      },
      {
        title: 'Marketing/Ecommerce',
        path: '/dashboard/Marketing/Ecommerce',
        icon: getIcon('fa6-solid:users'),
      },
      {
        title: 'funding',
        permission: 'fundingManagement',
        path: '/dashboard/funding',
        icon: getIcon('icon-park-outline:funds'),
      },
      {
        title: 'jobs/project',
        permission: 'jobprofileManagement',
        path: PATH_DASHBOARD.jobProject.root,
        icon: getIcon('ion:documents-sharp'),
      },
      {
        title: 'team',
        permission: 'teamManagement',
        path: PATH_DASHBOARD.users.root,
        icon: getIcon('fa6-solid:users'),
      },
      {
        title: 'Virtual Professional',
        permission: 'professionalManagement',
        path: '/dashboard/virtualProfessional',
        icon: getIcon('fluent:inprivate-account-24-filled'),
      },
    ],
  },
  // {
  //   title: 'dashboard',
  //   path: PATH_DASHBOARD.general.app,
  //   icon: ICONS.dashboard,
  // },
  // {
  //   title: 'master data',
  //   path: '/dashboard/master-data',
  //   icon: getIcon('bxs:data'),
  // },
  // {
  //   title: 'sales',
  //   path: '/dashboard/sales',
  //   icon: getIcon('icon-park-solid:sales-report'),
  // },
  // {
  //   title: 'purchase',
  //   path: '/dashboard/purchase',
  //   icon: getIcon('fa:cart-arrow-down'),
  // },
  // {
  //   title: 'receipt',
  //   path: '/dashboard/receipt',
  //   icon: getIcon('carbon:piggy-bank'),
  // },
  // {
  //   title: 'payment',
  //   path: '/dashboard/payment',
  //   icon: getIcon('bi:cash-coin'),
  // },
  // {
  //   title: 'journal/others',
  //   path: '/dashboard/journal',
  //   icon: getIcon('icon-park-solid:other'),
  // },
  // {
  //   title: 'debit/credit notes',
  //   path: '/dashboard/notes',
  //   icon: getIcon('charm:notes-cross'),
  // },
  // {
  //   title: 'payroll',
  //   path: '/dashboard/payroll',
  //   icon: getIcon('clarity:employee-group-solid'),
  // },
  // {
  //   title: 'report',
  //   path: '/dashboard/report',
  //   icon: getIcon('icon-park-solid:table-report'),
  // },
  // {
  //   title: 'funding',
  //   path: '/dashboard/funding',
  //   icon: getIcon('icon-park-outline:funds'),
  // },
  // {
  //   title: 'legal docs',
  //   path: '/dashboard/docs',
  //   icon: getIcon('ion:documents-sharp'),
  // },
  // {
  //   title: 'team',
  //   path: '/dashboard/team',
  //   icon: getIcon('fa6-solid:users'),
  // },
  // {
  //   title: 'virtual accountant',
  //   path: '/dashboard/accountant',
  //   icon: getIcon('fluent:inprivate-account-24-filled'),
  // },
];

export default navConfig;
