export const receptionishtquestion = [
    {
      question: 'What is the primary role of a receptionist?',
      options: [
        'Managing payroll',
        'Greeting visitors and answering phone calls',
        'Conducting interviews',
        'Organizing company events'
      ],
      answer: ''
    },
    {
      question: 'Which of the following skills is most important for a receptionist?',
      options: [
        'Programming',
        'Customer service',
        'Data analysis',
        'Sales'
      ],
      answer: ''
    },
    {
      question: 'What is the best way to handle a difficult customer?',
      options: [
        'Ignore them',
        'Listen actively and empathize',
        'Argue back',
        'Transfer them to someone else immediately'
      ],
      answer: ''
    },
    {
      question: 'Which tool is commonly used for scheduling appointments?',
      options: [
        'Spreadsheet',
        'Calendar software',
        'Word processor',
        'Email'
      ],
      answer: ''
    },
    {
      question: 'What does it mean to maintain confidentiality in an office setting?',
      options: [
        'Sharing information with all staff',
        'Keeping sensitive information private',
        'Posting personal information publicly',
        'None of the above'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist greet a visitor?',
      options: [
        'With a nod',
        'With a warm smile and a friendly greeting',
        'With silence',
        'By asking them to wait'
      ],
      answer: ''
    },
    {
      question: 'What is the appropriate response when answering the phone?',
      options: [
        'Hello?',
        'Who is this?',
        'Thank you for calling [Company Name], how may I assist you?',
        'What do you want?'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is an effective way to handle multiple incoming calls?',
      options: [
        'Answer them all at once',
        'Prioritize urgent calls and take messages for others',
        'Ignore all calls',
        'Transfer every call to another department'
      ],
      answer: ''
    },
    {
      question: 'What tone should a receptionist use when communicating with clients?',
      options: [
        'Casual and informal',
        'Professional and friendly',
        'Dismissive',
        'Monotone'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist ensure clear communication?',
      options: [
        'Speak as fast as possible',
        'Use jargon and technical terms',
        'Use simple language and confirm understanding',
        'Avoid eye contact'
      ],
      answer: ''
    },
    {
      question: 'What is an important task for a receptionist at the beginning of the day?',
      options: [
        'Cleaning the office',
        'Preparing the daily schedule',
        'Going through personal emails',
        'Leaving early'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist manage incoming mail?',
      options: [
        'Ignore it',
        'Sort, distribute, and log it appropriately',
        'Open it all',
        'Throw it away'
      ],
      answer: ''
    },
    {
      question: 'What type of software is commonly used for front desk operations?',
      options: [
        'Graphic design software',
        'Customer relationship management (CRM) software',
        'Video editing software',
        'Accounting software'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist handle scheduling conflicts?',
      options: [
        'Ignore them',
        'Speak to the involved parties to find a resolution',
        'Cancel all appointments',
        'Double book the time slot'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of a visitor log?',
      options: [
        'To keep track of employees',
        'To record visitor details for security and accountability',
        'To log personal information',
        'To manage phone calls'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist create a welcoming environment?',
      options: [
        'Keeping the reception area tidy and organized',
        'Playing loud music',
        'Ignoring visitors',
        'Leaving the area unattended'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do if they cannot answer a visitor\'s question?',
      options: [
        'Make up an answer',
        'Admit they don\'t know and offer to find out',
        'Dismiss the question',
        'Ignore the visitor'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist respond to a complaint?',
      options: [
        'Get defensive',
        'Listen, apologize, and offer a solution',
        'Tell the customer to leave',
        'Change the subject'
      ],
      answer: ''
    },
    {
      question: 'What is the best way to ensure customer satisfaction?',
      options: [
        'Following up after interactions',
        'Avoiding communication',
        'Being indifferent',
        'Ignoring feedback'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do if they receive a package for a colleague who is not in the office?',
      options: [
        'Open it',
        'Leave it unattended',
        'Secure it and inform the colleague upon their return',
        'Send it back'
      ],
      answer: ''
    },
    {
      question: 'What is a good way to prioritize tasks?',
      options: [
        'Doing the easiest tasks first',
        'Based on urgency and importance',
        'Randomly selecting tasks',
        'Focusing only on long-term projects'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist effectively manage their time?',
      options: [
        'By multitasking excessively',
        'By using to-do lists and calendars',
        'By working overtime',
        'By avoiding tasks'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do during slow periods?',
      options: [
        'Chat with colleagues',
        'Find ways to improve office efficiency',
        'Do personal tasks',
        'Leave early'
      ],
      answer: ''
    },
    {
      question: 'How can technology help a receptionist manage their duties?',
      options: [
        'By complicating processes',
        'By automating repetitive tasks and improving organization',
        'By providing distractions',
        'By creating additional work'
      ],
      answer: ''
    },
    {
      question: 'What is the importance of punctuality for a receptionist?',
      options: [
        'It sets a positive tone for the day',
        'It doesn\'t matter',
        'It only matters for management',
        'It is not relevant to the job'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist handle confidential information?',
      options: [
        'Discuss it openly with colleagues',
        'Keep it secure and only share with authorized personnel',
        'Write it down publicly',
        'Ignore confidentiality protocols'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do if they encounter a fire alarm?',
      options: [
        'Ignore it',
        'Follow emergency procedures and evacuate',
        'Continue working',
        'Call everyone to gather'
      ],
      answer: ''
    },
    {
      question: 'What is the best practice for answering a phone call?',
      options: [
        '"Hold on a second."',
        '"Thank you for calling [Company Name], this is [Your Name], how can I help you?"',
        '"What do you want?"',
        '"Can I call you back later?"'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist handle a visitor without an appointment?',
      options: [
        'Turn them away immediately',
        'Politely ask for their purpose and inform the appropriate staff',
        'Ignore them',
        'Allow them to enter the office'
      ],
      answer: ''
    },
    {
      question: 'What is the role of a receptionist in emergency situations?',
      options: [
        'To panic',
        'To follow emergency protocols and assist in evacuations',
        'To handle all emergencies alone',
        'To ignore protocols'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of an electronic filing system?',
      options: [
        'To complicate document retrieval',
        'To organize and store documents efficiently',
        'To make files harder to find',
        'To replace paper entirely'
      ],
      answer: ''
    },
    {
      question: 'Which of the following software is commonly used for managing office communication?',
      options: [
        'Photoshop',
        'Microsoft Teams or Slack',
        'Gaming software',
        'Music production software'
      ],
      answer: ''
    },
    {
      question: 'What is the function of a fax machine in an office?',
      options: [
        'To send and receive documents over a phone line',
        'To print documents',
        'To scan photos',
        'To create presentations'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist use spreadsheets effectively?',
      options: [
        'For personal budgeting only',
        'To track schedules, appointments, and inventory',
        'To create complex formulas',
        'Spreadsheets are not useful for receptionists'
      ],
      answer: ''
    },
    {
      question: 'What is the importance of keeping software updated?',
      options: [
        'To increase complexity',
        'To ensure security and functionality',
        'It\'s not important',
        'To slow down the system'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist interact with diverse clients?',
      options: [
        'Use slang',
        'Be respectful and adaptable',
        'Assume all clients are the same',
        'Avoid eye contact'
      ],
      answer: ''
    },
    {
      question: 'What is an effective way to build rapport with clients?',
      options: [
        'Ignoring their concerns',
        'Personalizing interactions and showing genuine interest',
        'Talking about personal problems',
        'Being indifferent'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist handle cultural differences?',
      options: [
        'By ignoring them',
        'By being aware and respectful of them',
        'By making jokes',
        'By being inflexible'
      ],
      answer: ''
    },
    {
      question: 'What role does empathy play in customer service?',
      options: [
        'It\'s not important',
        'It helps in understanding customer needs and concerns',
        'It complicates interactions',
        'It\'s only relevant for managers'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist demonstrate professionalism?',
      options: [
        'By dressing casually',
        'By being punctual and courteous',
        'By avoiding eye contact',
        'By engaging in gossip'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do when faced with an unexpected problem?',
      options: [
        'Panic',
        'Assess the situation and find a solution',
        'Ignore it',
        'Call someone else to fix it'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist effectively resolve scheduling conflicts?',
      options: [
        'By apologizing and finding an alternative time',
        'By blaming others',
        'By ignoring the issue',
        'By cancelling all appointments'
      ],
      answer: ''
    },
    {
      question: 'What is the best approach to take when handling a complaint?',
      options: [
        'Listen and provide a solution',
        'Defend the company',
        'Dismiss the complaint',
        'Change the subject'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist approach a situation where they don\'t know the answer?',
      options: [
        'Make something up',
        'Admit they don\'t know and offer to find the information',
        'Ignore the question',
        'Tell the caller to call back later'
      ],
      answer: ''
    },
    {
      question: 'What is the first step in conflict resolution?',
      options: [
        'Avoiding the conflict',
        'Understanding the issue from both perspectives',
        'Taking sides',
        'Escalating to management immediately'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of maintaining an organized reception area?',
      options: [
        'To impress visitors',
        'To create a professional image and facilitate workflow',
        'It\'s not important',
        'To make it easier to clean'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist contribute to team dynamics?',
      options: [
        'By being isolated',
        'By fostering communication and collaboration',
        'By avoiding involvement',
        'By criticizing others'
      ],
      answer: ''
    },
    {
      question: 'What is a key characteristic of a successful receptionist?',
      options: [
        'Indifference',
        'Proactiveness and adaptability',
        'Avoiding responsibility',
        'Excessive talking'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do if they feel overwhelmed with tasks?',
      options: [
        'Work faster',
        'Prioritize tasks and seek assistance if needed',
        'Stop working',
        'Leave for the day'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist show initiative?',
      options: [
        'By waiting for instructions',
        'By suggesting improvements and taking on additional responsibilities',
        'By staying out of office matters',
        'By focusing solely on their tasks'
      ],
      answer: ''
    },
    {
      question: 'What is customer relationship management (CRM)?',
      options: [
        'Managing customer complaints',
        'Maintaining detailed information about customer interactions',
        'Ignoring customer needs',
        'Selling products'
      ],
      answer: ''
    },
    {
      question: 'Why is it important to follow up with clients?',
      options: [
        'To check on their feedback and build relationships',
        'To annoy them',
        'It\'s not important',
        'To increase sales pressure'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist ensure accurate client information?',
      options: [
        'Guessing',
        'Verifying details when entering them into the system',
        'Ignoring the information',
        'Not asking for clarification'
      ],
      answer: ''
    },
    {
      question: 'What is an effective way to handle repeat customers?',
      options: [
        'Treat them the same as new customers',
        'Personalize interactions based on their history',
        'Ignore their previous visits',
        'Avoid them'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist deal with a difficult client repeatedly?',
      options: [
        'Ignore them',
        'Document interactions and seek assistance from management if necessary',
        'Argue with them',
        'Transfer them without explanation'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist manage stress in a busy environment?',
      options: [
        'Take breaks when possible and practice time management',
        'Work faster without breaks',
        'Ignore the stress',
        'Complain to colleagues'
      ],
      answer: ''
    },
    {
      question: 'What is an effective technique for staying calm under pressure?',
      options: [
        'Yelling',
        'Deep breathing and focusing on tasks',
        'Avoiding all responsibility',
        'Ignoring problems'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist respond to a high volume of visitors?',
      options: [
        'Panic and become overwhelmed',
        'Stay organized and prioritize tasks',
        'Ignore visitors',
        'Let them wait indefinitely'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist maintain a positive attitude?',
      options: [
        'By complaining about work',
        'By focusing on solutions and customer satisfaction',
        'By isolating themselves',
        'By being indifferent'
      ],
      answer: ''
    },
    {
      question: 'What is the importance of teamwork in a receptionist role?',
      options: [
        'It is not important',
        'It enhances efficiency and customer service',
        'It complicates tasks',
        'It creates competition'
      ],
      answer: ''
    },
    {
      question: 'Why is ongoing training important for receptionists?',
      options: [
        'To keep up with industry standards and improve skills',
        'It\'s not necessary',
        'To replace staff',
        'To limit responsibilities'
      ],
      answer: ''
    },
    {
      question: 'What can a receptionist do to improve their skills?',
      options: [
        'Avoid new challenges',
        'Seek feedback and participate in training programs',
        'Rely on others',
        'Stick to routine tasks'
      ],
      answer: ''
    },
    {
      question: 'How can networking benefit a receptionist?',
      options: [
        'It doesn\'t benefit them',
        'It can lead to new job opportunities and professional growth',
        'It creates conflict',
        'It wastes time'
      ],
      answer: ''
    },
    {
      question: 'What is a good way to stay updated on industry trends?',
      options: [
        'Avoid reading professional materials',
        'Follow industry news and join professional organizations',
        'Focus only on daily tasks',
        'Rely on colleagues'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist approach performance evaluations?',
      options: [
        'Ignore them',
        'Use them as a tool for personal and professional growth',
        'Complain about them',
        'View them as a threat'
      ],
      answer: ''
    },
    {
      question: 'Why is it important for a receptionist to know company policies?',
      options: [
        'To enforce rules and assist customers',
        'It\'s not important',
        'To argue with clients',
        'To ignore them'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist familiarize themselves with company services?',
      options: [
        'Ask colleagues occasionally',
        'Read company materials and ask questions',
        'Ignore the information',
        'Avoid learning'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist know about emergency procedures?',
      options: [
        'It\'s not their responsibility',
        'How to assist in emergencies and evacuations',
        'They can ignore them',
        'Only management needs to know'
      ],
      answer: ''
    },
    {
      question: 'Why is it important for a receptionist to have knowledge of the company’s clientele?',
      options: [
        'To ignore their needs',
        'To provide tailored service and build relationships',
        'To create confusion',
        'It\'s not necessary'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist contribute to a positive company image?',
      options: [
        'By being rude',
        'By providing excellent service and a welcoming demeanor',
        'By avoiding interactions',
        'By gossiping'
      ],
      answer: ''
    },
    {
      question: 'What should a receptionist do at the end of the day?',
      options: [
        'Leave without tidying up',
        'Ensure the reception area is clean and prepare for the next day',
        'Dismiss all responsibilities',
        'Stay late without reason'
      ],
      answer: ''
    },
    {
      question: 'How can a receptionist support team morale?',
      options: [
        'By spreading negativity',
        'By being supportive and encouraging teamwork',
        'By ignoring colleagues',
        'By focusing solely on personal tasks'
      ],
      answer: ''
    },
    {
      question: 'What is an essential quality for a receptionist when dealing with complaints?',
      options: [
        'Indifference',
        'Patience and empathy',
        'Arrogance',
        'Ignoring the issue'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist handle personal calls during work hours?',
      options: [
        'Make them loudly',
        'Keep them brief and discreet',
        'Ignore work responsibilities',
        'Make personal calls frequently'
      ],
      answer: ''
    },
    {
      question: 'What is the best way for a receptionist to stay organized?',
      options: [
        'Use sticky notes everywhere',
        'Utilize digital tools and maintain a clean workspace',
        'Rely on memory',
        'Create chaos'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist dress in a professional environment?',
      options: [
        'Casual and comfortable',
        'Professional and appropriate for the office culture',
        'Unkempt',
        'Overly formal'
      ],
      answer: ''
    },
    {
      question: 'What is the importance of punctuality for a receptionist?',
      options: [
        'It\'s irrelevant',
        'It sets a positive example and builds trust',
        'Only management needs to be punctual',
        'It creates stress'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist respond to personal questions from clients?',
      options: [
        'Share personal information freely',
        'Politely redirect the conversation to business matters',
        'Ignore the questions',
        'Answer them in detail'
      ],
      answer: ''
    },
    {
      question: 'What is the appropriate way to handle sensitive documents?',
      options: [
        'Leave them out in the open',
        'Keep them secure and limit access to authorized personnel',
        'Share them with everyone',
        'Discard them'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist conduct themselves in meetings?',
      options: [
        'Disrupt the meeting',
        'Listen actively and contribute when appropriate',
        'Ignore the meeting',
        'Be unprepared'
      ],
      answer: ''
    },
    {
      question: 'If a visitor is upset about a delay how should you respond?',
      options: [
        'Blame the situation',
        'Acknowledge their feelings and apologize for the wait',
        'Ignore their concerns',
        'Dismiss them'
      ],
      answer: ''
    },
    {
      question: 'What would you do if you accidentally misdirected a call?',
      options: [
        'Ignore it',
        'Apologize and help redirect the call to the right person',
        'Blame the caller',
        'Make excuses'
      ],
      answer: ''
    },
    {
      question: 'If a colleague asks for help during a busy period how should you respond?',
      options: [
        'Refuse to help',
        'Offer assistance if possible balancing your own tasks',
        'Ignore them',
        'Complain about it'
      ],
      answer: ''
    },
    {
      question: 'How would you handle a situation where a visitor refuses to sign in?',
      options: [
        'Let them in anyway',
        'Politely explain the importance of signing in for security',
        'Argue with them',
        'Ignore the situation'
      ],
      answer: ''
    },
    {
      question: 'If you receive a call for someone who is unavailable what should you do?',
      options: [
        'Hang up',
        'Take a message and offer to have them call back',
        'Tell the caller to call back later',
        'Ignore the call'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of a company’s dress code?',
      options: [
        'To limit expression',
        'To maintain professionalism and a positive image',
        'To confuse employees',
        'It\'s unnecessary'
      ],
      answer: ''
    },
    {
      question: 'Why should a receptionist be familiar with emergency contact procedures?',
      options: [
        'It\'s not important',
        'To ensure safety and quick response in emergencies',
        'To ignore emergencies',
        'Only management needs to know'
      ],
      answer: ''
    },
    {
      question: 'What should you do if you notice a security issue in the office?',
      options: [
        'Ignore it',
        'Report it immediately to the appropriate personnel',
        'Handle it yourself',
        'Discuss it with colleagues'
      ],
      answer: ''
    },
    {
      question: 'How should a receptionist approach company policy updates?',
      options: [
        'Ignore them',
        'Review and implement them as necessary',
        'Complain about them',
        'Dismiss them'
      ],
      answer: ''
    },
    {
      question: 'Why is understanding the company\'s mission and values important for a receptionist?',
      options: [
        'It\'s not relevant',
        'It helps in aligning customer service with company goals',
        'To create confusion',
        'Only management needs to know'
      ],
      answer: ''
    },
    {
      question: 'What motivates you in your role as a receptionist?',
      options: [
        'Personal gain only',
        'Helping others and providing excellent service',
        'Avoiding responsibility',
        'Ignoring feedback'
      ],
      answer: ''
    },
    {
      question: 'How do you define success in your position?',
      options: [
        'Completing tasks',
        'Satisfied clients and a well-organized office',
        'Meeting deadlines only',
        'Being busy'
      ],
      answer: ''
    },
    {
      question: 'What is the biggest challenge you face in a receptionist role?',
      options: [
        'Too much downtime',
        'Managing multiple priorities and client needs',
        'Limited interaction',
        'Lack of responsibility'
      ],
      answer: ''
    },
    {
      question: 'How do you handle constructive criticism?',
      options: [
        'Ignore it',
        'Take it as an opportunity to improve',
        'Get defensive',
        'Argue about it'
      ],
      answer: ''
    },
    {
      question: 'What do you hope to achieve in your role as a receptionist?',
      options: [
        'A paycheck',
        'Professional growth and contributing to team success',
        'Minimal effort',
        'To avoid responsibility'
      ],
      answer: ''
    },
    {
      question: 'How can you contribute to a positive work environment?',
      options: [
        'By being negative',
        'By being supportive and proactive',
        'By avoiding teamwork',
        'By gossiping'
      ],
      answer: ''
    },
    {
      question: 'What are your long-term career goals?',
      options: [
        'To stay in the same position indefinitely',
        'To advance within the company and develop new skills',
        'To avoid responsibilities',
        'To switch careers entirely'
      ],
      answer: ''
    },
    {
      question: 'How would you describe your work style?',
      options: [
        'Chaotic',
        'Organized and detail-oriented',
        'Disorganized',
        'Avoidant'
      ],
      answer: ''
    },
    {
      question: 'What do you enjoy most about being a receptionist?',
      options: [
        'Interacting with people',
        'The routine',
        'Avoiding responsibility',
        'Working alone'
      ],
      answer: ''
    },
    {
      question: 'Why do you want to work for our company?',
      options: [
        'It\'s just a job',
        'I admire the company’s values and culture',
        'I need a paycheck',
        'I have no other options'
      ],
      answer: ''
    }
  ];
  