import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  id: null,
  shipId: null,
  isReverse: null,
  ship: null,
  invoice: null,
  shipShow: false,
  check: true,
  open: false,
  supplyId: 6,
  pay: 0,
  selectTax: 0,
  taxAdjustment: null,
  payableAmount: 0,
  shipForm: {},
  duplicate: false,
  ledger: null,
  tab: 'invoice',
  // sales setting work
  salesSetting: {},
  placeSupply: 7,
  shipSave: null,
  shipState: null,

  // quiz
  quiz: null,
  quizCateogry:null
};

const slice = createSlice({
  name: 'salesprop',
  initialState,
  reducers: {
  setQuizCateogry(state, action) {
      state.quizCateogry = action.payload;
    },
    setQuiz(state, action) {
      state.quiz = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setDuplicate(state, action) {
      state.duplicate = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setShipId(state, action) {
      state.shipId = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setShip(state, action) {
      state.ship = [action.payload];
    },
    setCheck(state, action) {
      state.check = action.payload;
    },
    setShipShow(state, action) {
      state.shipShow = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setSupplyId(state, action) {
      state.supplyId = action.payload;
    },
    setPay(state, action) {
      state.pay = action.payload;
    },

    setInvoice(state, action) {
      state.invoice = action.payload;
    },
    setTaxAdjustment(state, action) {
      state.taxAdjustment = action.payload;
    },
    setSelectTax(state, action) {
      state.selectTax = action.payload;
    },
    setPlaceSupply(state, action) {
      state.placeSupply = action.payload;
    },
    setIsReverse(state, action) {
      state.isReverse = action.payload;
    },
    setShipSave(state, action) {
      state.shipSave = action.payload;
    },
    setShipState(state, action) {
      state.shipState = action.payload;
    },
    setShipForm(state, action) {
      state.shipForm = action.payload;
    },
    // sales setting

    setSalesSetting(state, action) {
      const salesSetting = action.payload;
      state.salesSetting = salesSetting;
      // state.salesSetting = action.payload;
    },

    setPayableAmount(state, action) {
      state.payableAmount = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setQuizCateogry,
  setQuiz,
  setTab,
  setId,
  setShipId,
  setData,
  setShip,
  setCheck,
  setShipShow,
  setOpen,
  setSupplyId,
  setPay,
  setInvoice,
  setTaxAdjustment,
  setSelectTax,
  setIsReverse,
  setPayableAmount,
  setPlaceSupply,
  setShipSave,
  setShipState,
  setShipForm,
  setSalesSetting,
  setDuplicate,
  setLedger,
} = slice.actions;

export const getSalesSetting = () => async (dispatch) => {
  try {
    await axios.get('/sales/saleSettings').then((response) => dispatch(setSalesSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
