import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container ,Typography} from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// routes
import { PATH_AUTH } from '../../routes/paths';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
// import img from '../../image/startup.png';
import img from '../../image/JOB1.png';
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 'inset 0px 4px 6px rgba(0, 0, 0, 0.1)', bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Box
          sx={{
    
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width:'100%',
            mx:{xs:1, sm:2, md:12}, 

          }}
        >
          <Box sx={{width:{xs:'90px', md:'110px'}, height:{xs:'90px', md:'110px'}}}>
          <img src={img} alt="err" />
          </Box>

<Box sx={{
     width: '70%',
     maxWidth: '800px',
    //  background: 'linear-gradient(45deg, #ffd700, #ffcc00, #f7b500)',
     borderRadius: '50px',
     padding: '15px',
    //  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     overflow: 'hidden',
     position: 'relative',
     animation: 'glowing-border 1.5s infinite alternate'
}}>
  <Typography sx={{
      display: 'inline-block',
      fontSize: '2rem',
      fontWeight: 700,
      color: 'black',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      whiteSpace: 'nowrap',
      animation: 'scroll-text 12s linear infinite, sparkle-text 1.5s ease-in-out infinite',
      '@keyframes scroll-text' :{
       '0%':{
            transform: 'translateX(100%)'
        },
       '100%':{
            transform: 'translateX(-100%)'
        }
    }
  }}>
 🏆 Winner will be announce on coming saturday , participate now to become winner ! 🌟
  </Typography>
</Box>
          <Box sx={{ flexGrow: 1 }} />



          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
          

          {/* <Button variant="contained" size="medium"
          sx={{px:2.8}}
          >
            Register
          </Button> */}

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Box>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
