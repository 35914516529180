export const country = [
    { id: '1', label: 'UNITED KINGDOM' },
    { id: '2', label: 'ARGENTINA' },
    { id: '3', label: 'AUSTRALIA' },
    { id: '4', label: 'BAHAMAS' },
    { id: '5', label: 'BELGIUM' },
    { id: '6', label: 'BRAZIL' },
    { id: '7', label: 'CANADA' },
    { id: '8', label: 'CHINA' },
    { id: '9', label: 'COLOMBIA' },
    { id: '10', label: 'CUBA' },
    { id: '11', label: 'DOMINICAN REPUBLIC' },
    { id: '12', label: 'ECUADOR' },
    { id: '13', label: 'EL SALVADOR' },
    { id: '14', label: 'FRANCE' },
    { id: '15', label: 'GERMANY' },
    { id: '16', label: 'GUATEMALA' },
    { id: '17', label: 'HAITI' },
    { id: '18', label: 'HONDURAS' },
    { id: '19', label: 'INDIA' },
    { id: '20', label: 'IRELAND' },
    { id: '21', label: 'ISRAEL' },
    { id: '22', label: 'ITALY' },
    { id: '23', label: 'JAPAN' },
    { id: '24', label: 'KOREA' },
    { id: '25', label: 'MEXICO' },
    { id: '26', label: 'NETHERLANDS' },
    { id: '27', label: 'PHILIPPINES' },
    { id: '28', label: 'SPAIN' },
    { id: '29', label: 'SWEDEN' },
    { id: '30', label: 'SWITZERLAND' },
    { id: '31', label: 'TAIWAN' },
    { id: '32', label: 'VENEZUELA' },
    { id: '33', label: 'VIET NAM' },
    { id: '34', label: 'AFGHANISTAN' },
    { id: '35', label: 'ALBANIA' },
    { id: '36', label: 'ALGERIA' },
    { id: '37', label: 'AMERICAN SAMOA' },
    { id: '38', label: 'ANDORRA' },
    { id: '39', label: 'ANGOLA' },
    { id: '40', label: 'ARMENIA' },
    { id: '41', label: 'AUSTRIA' },
    { id: '42', label: 'AZERBAIJAN' },
    { id: '43', label: 'BAHRAIN' },
    { id: '44', label: 'BANGLADESH' },
    { id: '45', label: 'BARBADOS' },
    { id: '46', label: 'BELARUS' },
    { id: '47', label: 'BELIZE' },
    { id: '48', label: 'BENIN' },
    { id: '49', label: 'BERMUDA' },
    { id: '50', label: 'BHUTAN' },
    { id: '51', label: 'BOLIVIA' },
    { id: '52', label: 'BOSNIA AND HERZEGOVINA' },
    { id: '53', label: 'BOTSWANA' },
    { id: '54', label: 'BULGARIA' },
    { id: '55', label: 'BURKINA FASO' },
    { id: '56', label: 'BURUNDI' },
    { id: '57', label: 'CAMBODIA' },
    { id: '58', label: 'CAMEROON' },
    { id: '59', label: 'CAPE VERDE ISLANDS' },
    { id: '60', label: 'CHAD' },
    { id: '61', label: 'CHILE' },
    { id: '62', label: 'CONGO' },
    { id: '63', label: 'COSTA RICA' },
    { id: '64', label: 'CROATIA' },
    { id: '65', label: 'CYPRUS' },
    { id: '66', label: 'CZECH REPUBLIC' },
    { id: '67', label: 'DENMARK' },
    { id: '68', label: 'DJIBOUTI' },
    { id: '69', label: 'DOMINICA' },
    { id: '70', label: 'EGYPT' },
    { id: '71', label: 'ERITREA' },
    { id: '72', label: 'ESTONIA' },
    { id: '73', label: 'ETHIOPIA' },
    { id: '74', label: 'FIJI' },
    { id: '75', label: 'FINLAND' },
    { id: '76', label: 'FRENCH POLYNESIA' },
    { id: '77', label: 'GABON' },
    { id: '78', label: 'GAMBIA' },
    { id: '79', label: 'GEORGIA' },
    { id: '80', label: 'GHANA' },
    { id: '81', label: 'GREECE' },
    { id: '82', label: 'GRENADA' },
    { id: '83', label: 'GUINEA' },
    { id: '84', label: 'GUYANA' },
    { id: '85', label: 'HUNGARY' },
    { id: '86', label: 'ICELAND' },
    { id: '87', label: 'INDONESIA' },
    { id: '88', label: 'IRAN' },
    { id: '89', label: 'IRAQ' },
    { id: '90', label: 'JAMAICA' },
    { id: '91', label: 'JORDAN' },
    { id: '92', label: 'KAZAKHSTAN' },
    { id: '93', label: 'KENYA' },
    { id: '94', label: 'KOREA' },
    { id: '95', label: 'KUWAIT' },
    { id: '96', label: 'LATVIA' },
    { id: '97', label: 'LEBANON' },
    { id: '98', label: 'LIBERIA' },
    { id: '99', label: 'LIBYA' },
    { id: '100', label: 'LITHUANIA' },
    { id: '101', label: 'LUXEMBOURG' },
    { id: '102', label: 'MADAGASCAR' },
    { id: '103', label: 'MALAWI' },
    { id: '104', label: 'MALAYSIA' },
    { id: '105', label: 'MALDIVES' },
    { id: '106', label: 'MALI' },
    { id: '107', label: 'MALTA' },
    { id: '108', label: 'MAURITANIA' },
    { id: '109', label: 'MAURITIUS' },
    { id: '110', label: 'MONACO' },
    { id: '111', label: 'MONGOLIA' },
    { id: '112', label: 'MONTENEGRO' },
    { id: '113', label: 'MOROCCO' },
    { id: '114', label: 'MOZAMBIQUE' },
    { id: '115', label: 'NAMIBIA' },
    { id: '116', label: 'NEPAL' },
    { id: '117', label: 'NEW ZEALAND' },
    { id: '118', label: 'NICARAGUA' },
    { id: '119', label: 'NIGER' },
    { id: '120', label: 'NIGERIA' },
    { id: '121', label: 'NORWAY' },
    { id: '122', label: 'OMAN' },
    { id: '123', label: 'PAKISTAN' },
    { id: '124', label: 'PANAMA' },
    { id: '125', label: 'PAPUA NEW GUINEA' },
    { id: '126', label: 'PARAGUAY' },
    { id: '127', label: 'PERU' },
    { id: '128', label: 'POLAND' },
    { id: '129', label: 'PORTUGAL' },
    { id: '130', label: 'QATAR' },
    { id: '131', label: 'ROMANIA' },
    { id: '132', label: 'RWANDA' },
    { id: '133', label: 'SAUDI ARABIA' },
    { id: '134', label: 'SENEGAL' },
    { id: '135', label: 'SERBIA' },
    { id: '136', label: 'SIERRA LEONE' },
    { id: '137', label: 'SINGAPORE' },
    { id: '138', label: 'SLOVAKIA' },
    { id: '139', label: 'SLOVENIA' },
    { id: '140', label: 'SOLOMON ISLANDS' },
    { id: '141', label: 'SOMALIA' },
    { id: '142', label: 'SOUTH AFRICA' },
    { id: '143', label: 'SRI LANKA' },
    { id: '144', label: 'SUDAN' },
    { id: '145', label: 'SURINAME' },
    { id: '146', label: 'SWAZILAND' },
    { id: '147', label: 'TAJIKISTAN' },
    { id: '148', label: 'THAILAND' },
    { id: '149', label: 'TOGO' },
    { id: '150', label: 'TRINIDAD AND TOBAGO' },
    { id: '151', label: 'TUNISIA' },
    { id: '152', label: 'TURKEY' },
    { id: '153', label: 'TURKMENISTAN' },
    { id: '154', label: 'TUVALU' },
    { id: '155', label: 'UGANDA' },
    { id: '156', label: 'UKRAINE' },
    { id: '157', label: 'UNITED ARAB EMIRATES' },
    { id: '158', label: 'UNITED STATES' },
    { id: '159', label: 'URUGUAY' },
    { id: '160', label: 'UZBEKISTAN' },
    { id: '161', label: 'VANUATU' },
    { id: '162', label: 'YEMEN' },
    { id: '163', label: 'ZAMBIA' },
];