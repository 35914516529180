// ----------------------------------------------------------------------



function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  quiz: '/quiz',
  TermsOfService:'/terms-of-service',
  RefundPolicy:'/refund-policy',
  congratulation:'/Congratulation'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    salesSetting: path(ROOTS_DASHBOARD, '/setting/sales-setting'),
    purchaseSetting: path(ROOTS_DASHBOARD, '/setting/purchase-setting'),
    jobProjectSetting: path(ROOTS_DASHBOARD, '/setting/job-project-setting'),
    taxSetting: path(ROOTS_DASHBOARD, '/setting/tax-setting'),
    whatsUpSetting: path(ROOTS_DASHBOARD, '/setting/whatsup-setting'),
    emailSetting: path(ROOTS_DASHBOARD, '/setting/email-setting'),
  },
  branches: {
    branch: path(ROOTS_DASHBOARD, '/branches/branch'),
    newBranch: path(ROOTS_DASHBOARD, '/branches/new-branch'),
  },

  masterData: {
    ledgerParty: path(ROOTS_DASHBOARD, '/master-data/ledger-party'),
    newLedgerParty: path(ROOTS_DASHBOARD, '/master-data/new-ledger-party'),
    editLedgerParty: path(ROOTS_DASHBOARD, '/master-data/edit-ledger-party'),
    ledgerDetails: path(ROOTS_DASHBOARD, '/master-data/ledger-details'),
    inventoryMaster: path(ROOTS_DASHBOARD, '/master-data/inventory-master'),
    newInventory: path(ROOTS_DASHBOARD, '/master-data/new-inventory-master'),
    editInventory: path(ROOTS_DASHBOARD, `/master-data/edit-inventory-master`),
    serviceMaster: path(ROOTS_DASHBOARD, '/master-data/service-master'),
    newServiceMaster: path(ROOTS_DASHBOARD, '/master-data/new-service-master'),
    editServiceMaster: path(ROOTS_DASHBOARD, '/master-data/edit-service-master'),
  },

  users: {
    root: path(ROOTS_DASHBOARD, '/users/user'),
    newUser: path(ROOTS_DASHBOARD, '/users/new-user'),
    editUser: path(ROOTS_DASHBOARD, `/users/edit-user`),
  },

  sales: {
    invoiceList: path(ROOTS_DASHBOARD, '/sales/invoice-list'),
    newInvoice: path(ROOTS_DASHBOARD, '/sales/new-invoice'),
    editInvoice: path(ROOTS_DASHBOARD, '/sales/edit-invoice'),
    newBill: path(ROOTS_DASHBOARD, '/sales/new-bill'),
    editBill: path(ROOTS_DASHBOARD, '/sales/edit-bill'),
    newExport: path(ROOTS_DASHBOARD, '/sales/new-export-invoice'),
    editExport: path(ROOTS_DASHBOARD, '/sales/edit-export-invoice'),
    delivery: path(ROOTS_DASHBOARD, '/sales/delivery'),
    editDelivery: path(ROOTS_DASHBOARD, '/sales/edit-delivery'),
    creditNote: path(ROOTS_DASHBOARD, '/sales/credit-note'),
    editCreditNote: path(ROOTS_DASHBOARD, '/sales/change-credit-note'),
    salesOrder: path(ROOTS_DASHBOARD, '/sales/sales-order'),
    editSalesOrder: path(ROOTS_DASHBOARD, '/sales/edit-sales-order'),
    performaInvoice: path(ROOTS_DASHBOARD, '/sales/performa-invoice'),
    editPerformaInvoice: path(ROOTS_DASHBOARD, '/sales/edit-performa-invoice'),
    estimation: path(ROOTS_DASHBOARD, '/sales/estimation'),
    editEstimation: path(ROOTS_DASHBOARD, '/sales/edit-estimation'),
    debitNote: path(ROOTS_DASHBOARD, '/sales/debit-note'),
    editDebitNote: path(ROOTS_DASHBOARD, '/sales/edit-debit-note'),
    newDebitNote: path(ROOTS_DASHBOARD, '/sales/new-debit-note'),
    otherIncome: path(ROOTS_DASHBOARD, '/sales/other-income'),
    editOtherIncome: path(ROOTS_DASHBOARD, '/sales/edit-other-income'),
    newOtherIncome: path(ROOTS_DASHBOARD, '/sales/new-other-income'),
    stockTransfer: path(ROOTS_DASHBOARD, '/sales/stock-transfer'),
    editStockTransfer: path(ROOTS_DASHBOARD, '/sales/edit-stock-transfer'),
    newStockTransfer: path(ROOTS_DASHBOARD, '/sales/new-stock-transfer'),
  },
  reciept: {
    root: path(ROOTS_DASHBOARD, '/reciept'),
    newReciept: path(ROOTS_DASHBOARD, '/reciept/new-reciept'),
    editReciept: path(ROOTS_DASHBOARD, '/reciept/edit-reciept'),
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment'),
    newPayment: path(ROOTS_DASHBOARD, '/payment/new-payment'),
    editPayment: path(ROOTS_DASHBOARD, '/payment/edit-payment'),
  },
  expenseOrJournal: {
    root: path(ROOTS_DASHBOARD, '/expense-journal'),
    newExpenseOrJournal: path(ROOTS_DASHBOARD, '/expense-journal/new-expense-journal'),
  },
  purchase: {
    root: path(ROOTS_DASHBOARD, '/purchase'),
    newPurchase: path(ROOTS_DASHBOARD, '/purchase/new-purchase'),
    editPurchase: path(ROOTS_DASHBOARD, '/purchase/edit-purchase'),
    purchaseBill: path(ROOTS_DASHBOARD, '/purchase/purchase-bill'),
    editPurchaseBill: path(ROOTS_DASHBOARD, '/purchase/edit-purchase-bill'),
    purchaseExport: path(ROOTS_DASHBOARD, '/purchase/purchase-export'),
    editPurchaseExport: path(ROOTS_DASHBOARD, '/purchase/edit-purchase-export'),
  },
  jobProject: {
    root: path(ROOTS_DASHBOARD, '/job-project'),
    newJob: path(ROOTS_DASHBOARD, '/job-project/new-job'),
    newProject: path(ROOTS_DASHBOARD, '/job-project/new-project'),
  },
  tax: {
    gst: path(ROOTS_DASHBOARD, '/tax/gst'),
    gstr1: path(ROOTS_DASHBOARD, '/tax/gstr-1'),
    downloadGstr1: path(ROOTS_DASHBOARD, '/tax/download-gstr-1'),
    gstr9: path(ROOTS_DASHBOARD, '/tax/gstr-9'),
    gstr2a: path(ROOTS_DASHBOARD, '/tax/gstr-2a'),
    gstr3b: path(ROOTS_DASHBOARD, '/tax/gstr-3b'),
    pmt6: path(ROOTS_DASHBOARD, '/tax/pmt-6'),
    itc04: path(ROOTS_DASHBOARD, '/tax/ITC-04'),
    tds: path(ROOTS_DASHBOARD, '/tax/tds'),
    tcs: path(ROOTS_DASHBOARD, '/tax/tcs'),
    incomeTax: path(ROOTS_DASHBOARD, '/tax/incomeTax'),
    vat: path(ROOTS_DASHBOARD, '/tax/vat'),
    duty: path(ROOTS_DASHBOARD, '/tax/duty'),
  },

  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    financial: path(ROOTS_DASHBOARD, '/report/financial'),
  },

  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
