import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newTeam: [],
};

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {

    setNewTeam(state, action) {
      const newTeam = action.payload;
      state.newTeam = newTeam;
    },

    setNewTeamEdit(state, action) {
      const newTeamEdit = action.payload;
      state.newTeamEdit = newTeamEdit;
    },

  },
});

export default slice.reducer;

export const { setNewTeam, setNewTeamEdit } = slice.actions;


export const getTeam = () => async (dispatch) => {
  try {
    await axios.get('/subuserAll').then((response) => dispatch(setNewTeam(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTeamEdit = (id) => async dispatch => {
  try {
    await axios.get(`/subuser?ID=${id}`).then((response) => dispatch(setNewTeamEdit(response.data)));
  } catch (error) {
    return console.error(error.message)
  }
  return true;
}
