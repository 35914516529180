import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // job setting work
  jobSetting: {
    prefixInvoice: '',
    poNo: false,
    ebn: false,
    mot: false,
    col1: false,
    col2: false,
    col3: false,
    col1Name: '',
    col2Name: '',
    col3Name: '',
    job: false,
    disc: false,
    dis: false,
    transId: false,
    vehicleNo: false,
  },
};

const slice = createSlice({
  name: 'jobproject',
  initialState,
  reducers: {
    setJobSetting(state, action) {
      state.jobSetting = action.payload;
    },
  },
});

export default slice.reducer;

export const { setJobSetting } = slice.actions;
