import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  accountMode: [],
  partyName: [],
  invoiceList: [],
  reciept: [],
  recieptEdit:null,
  graph:{}
};

const slice = createSlice({
  name: 'reciept',
  initialState,
  reducers: {
    setAccountMode(state, action) {
      state.accountMode = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setReciept(state, action) {
      state.reciept = action.payload;
    },
    setRecieptEdit(state, action) {
      state.recieptEdit = action.payload;
    },
    setGraph(state, action) {
      state.graph = action.payload;
    },
    
  },
});

export default slice.reducer;

export const { setAccountMode, setPartyName, setInvoiceList, setReciept, setRecieptEdit,setGraph } = slice.actions;

export const getAccountMode = () => async (dispatch) => {
  try {
    await axios.get('/reciept/accountMode').then((response) => dispatch(setAccountMode(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPartyName = () => async (dispatch) => {
  try {
    await axios.get('/reciept/partyName').then((response) => dispatch(setPartyName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceList = (id) => async (dispatch) => {
  try {
    await axios.get(`/reciept/invoices?ledger_ID=${id}`).then((response) => dispatch(setInvoiceList(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getReciept = () => async (dispatch) => {
  try {
    await axios.get('/reciept/recieptAll').then((response) => dispatch(setReciept(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRecieptEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/reciept/reciept?ID=${id}`).then((response) => dispatch(setRecieptEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const  getGraph = () => async (dispatch) => {
  try {
    await axios.get('/reciept/summary/reciept').then((response) => dispatch(setGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};