import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  // purchase setting work
  purchaseSetting: {
    prefixInvoice: '',
    poNo: false,
    ebn: false,
    mot: false,
    col1: false,
    col2: false,
    col3: false,
    col1Name: '',
    col2Name: '',
    col3Name: '',
    job: false,
    disc: false,
    dis: false,
    transId: false,
    vehicleNo: false,
  },
  tab: 'purchase',
  purchase: [],
  graph: {},
  voucher: null,
  inventory: [],
  ledger: [],
  bill: [],
  billGraph: null,
  import: [],
  importGraph: null,
  importNo: null,
  total:0,
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setImportNo(state, action) {
      state.importNo = action.payload;
    },
    setImport(state, action) {
      state.import = action.payload;
    },
    setImportGraph(state, action) {
      state.importGraph = action.payload;
    },

    setTab(state, action) {
      state.tab = action.payload;
    },
    setBill(state, action) {
      state.bill = action.payload;
    },
    setBillGraph(state, action) {
      state.billGraph = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setPurchaseSetting(state, action) {
      state.purchaseSetting = action.payload;
    },
    setPurchase(state, action) {
      state.purchase = action.payload;
    },
    setGraph(state, action) {
      state.graph = action.payload;
    },
    setVoucher(state, action) {
      state.voucher = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    setTotal(state,action){
      state.total = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setPurchaseSetting,
  setPurchase,
  setGraph,
  setVoucher,
  setInventory,
  setLedger,
  setBill,
  setTab,
  setBillGraph,
  setImportGraph,
  setImport,
  setImportNo,
  setTotal,
} = slice.actions;

export const getImportNo = () => async (dispatch) => {
  try {
    await axios.get('/purchase/voucherNumber/PIT').then((response) => dispatch(setImportNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImport = () => async (dispatch) => {
  try {
    await axios.get('/purchase/importAll').then((response) => dispatch(setImport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImportGraph = () => async (dispatch) => {
  try {
    await axios.get('/purchase/summary/PIT').then((response) => dispatch(setImportGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBill = () => async (dispatch) => {
  try {
    await axios.get('/purchase/billofsupplyAll').then((response) => dispatch(setBill(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBillGraph = () => async (dispatch) => {
  try {
    await axios.get('/purchase/summary/PBS').then((response) => dispatch(setBillGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedger = () => async (dispatch) => {
  try {
    await axios.get('/purchase/ledger').then((response) => dispatch(setLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSetting = () => async (dispatch) => {
  try {
    await axios.get('/purchase/settings').then((response) => dispatch(setPurchaseSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGraph = () => async (dispatch) => {
  try {
    await axios.get('/purchase/summary/PCE').then((response) => dispatch(setGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getVoucher = () => async (dispatch) => {
  try {
    await axios.get('/purchase/voucherNumber/PCE').then((response) => dispatch(setVoucher(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventory = () => async (dispatch) => {
  try {
    await axios.get('/purchase/inventory').then((response) => dispatch(setInventory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchase = () => async (dispatch) => {
  try {
    await axios.get('/purchase/purchaseAll').then((response) => dispatch(setPurchase(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
