import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newInventry: [],
  newLedger: [],
  newService: [],
  newTransaction: [],
};

const slice = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {

    setNewLedger(state, action) {
      const newLedger = action.payload;
      state.newLedger = newLedger;
    },

    setLedgerEdit(state, action) {
      const newLedgerEdit = action.payload;
      state.newLedgerEdit = newLedgerEdit;
    },

    setNewInventory(state, action) {
      const newInventry = action.payload;
      state.newInventry = newInventry;
    },

    setInventoryEdit(state, action) {
      const newInventoryEdit = action.payload;
      state.newInventoryEdit = newInventoryEdit;
    },

    setNewService(state, action) {
      const newService = action.payload;
      state.newService = newService;
    },

    setServiceEdit(state, action) {
      const newServiceEdit = action.payload;
      state.newServiceEdit = newServiceEdit;
    },

    setLedgerTransaction(state, action) {
      const newTransaction = action.payload;
      state.newTransaction = newTransaction;
    },

  },
});

export default slice.reducer;

export const { setNewInventory, setNewLedger, setInventoryEdit, setInventoryFilter, setLedgerEdit, setNewService, setServiceEdit, setLedgerTransaction } = slice.actions;

export const getLedger = (categoryId = '') => async (dispatch) => {
  try {
    await axios.get(`/ledger-partyAll?category_id=${categoryId}`).then((response) => dispatch(setNewLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedgerEdit = (id) => async dispatch => {
  try {
    await axios.get(`ledger-party?ID=${id}`).then((response) => dispatch(setLedgerEdit(response.data)));
  } catch (error) {
    return console.error(error.message)
  }
  return true;
}

export const getInventory = () => async (dispatch) => {
  try {
    await axios.get('/inventoryAll').then((response) => dispatch(setNewInventory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventoryEdit = (id) => async dispatch => {
  try {
    await axios.get(`/inventory?ID=${id}`).then((response) => dispatch(setInventoryEdit(response.data)));
  } catch (error) {
    return console.error(error.message)
  }
  return true;
}

export const getService = () => async (dispatch) => {
  try {
    await axios.get('/serviceAll').then((response) => dispatch(setNewService(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getServiceEdit = (id) => async dispatch => {
  try {
    await axios.get(`/service?ID=${id}`).then((response) => dispatch(setServiceEdit(response.data)));
  } catch (error) {
    return console.error(error.message)
  }
  return true;
}

export const getLedgerTransaction = (id, startDate, endDate) => async dispatch => {
  try {
    await axios.get(`/ledger/ledgerTransactions/${id}?start=${startDate}&end=${endDate}`).then((response) => dispatch(setLedgerTransaction(response.data)));
  } catch (error) {
    return console.error(error.message)
  }
  return true;
}