import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import masterdataReducer from './slices/masterdata';
import branchesReducer from './slices/branches';
import teamReducer from './slices/team';
import salesReducer from './slices/sales';
import salespropReducer from './slices/salesprop';
import purchaseReducer from './slices/purchase';
import jobProjectReducer from './slices/jobproject';
import recieptReducer from './slices/reciept';
import paymentReducer from './slices/payment';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  user: userReducer,
  masterdata: masterdataReducer,
  branches: branchesReducer,
  team: teamReducer,
  sales: salesReducer,
  salesprop: salespropReducer,
  purchase: purchaseReducer,
  jobproject: jobProjectReducer,
  reciept: recieptReducer,
  payment: paymentReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
