// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
  colors:'red',
};

const menuConfig = [
  // {
  //   title: 'Home',
  //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
  //   path: '/',
  // },
  // {
  //   title: 'Contact',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: 'mailto:support@startupkhata.com',
  // },

  // {
  //   title: 'Become Partner',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: '',
  // },
  // {
  //   title: 'For Corporate',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: '',
  // },
];

export default menuConfig;
