import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newInvoice: [],
  show: false,
  invoiceNo: null,
  billNo: null,
  exportNo: null,
  deliveryNo: null,
  creditNo: null,
  salesOrderNo: null,
  performaNo: null,
  estimationNo: null,
  inventoryName: null,
  debitNo: null,

  // sales terms and condition
  termsCondition: null,
  billTerms: null,
  exportTerms: null,
  deliveryTerms: null,
  creditTerms: null,
  salesOrderTerms: null,
  performaTerms: null,
  estimationTerms: null,
  debitTerms: null,
  // sales form Data
  invoiceData: null,
  billData: null,
  exportData: null,
  salesOrderData: null,
  deliveryData: null,
  creditData: null,
  performaData: null,
  estimationData: null,
  debitData: null,
  // sales All Graph
  invoiceGraph: null,
  billGraph: null,
  exportGraph: null,
  deliveryGraph: null,
  creditGraph: null,
  salesOrderGraph: null,
  performaGraph: null,
  estimationGraph: null,
  debitGraph: null,
  // sales Edit
  invoiceEdit: null,
  billEdit: null,
  exportEdit: null,
  deliveryEdit: null,
  creditEdit: null,
  salesOrderEdit: null,
  performaEdit: null,
  estimationEdit: null,
  debitEdit: null,
};

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setDebitTerms(state, action) {
      state.debitTerms = action.payload;
    },
    setDebitGraph(state, action) {
      state.debitGraph = action.payload;
    },
    setDebitNo(state, action) {
      state.debitNo = action.payload;
    },
    setDebitdata(state, action) {
      state.debitData = action.payload;
    },
    setDebitEdit(state, action) {
      state.debitEdit = action.payload;
    },
    setEstimationEdit(state, action) {
      state.estimationEdit = action.payload;
    },
    setPerformaEdit(state, action) {
      state.performaEdit = action.payload;
    },
    setSalesOrderEdit(state, action) {
      state.salesOrderEdit = action.payload;
    },
    setCreditEdit(state, action) {
      state.creditEdit = action.payload;
    },
    setDeliveryEdit(state, action) {
      state.deliveryEdit = action.payload;
    },
    setExportEdit(state, action) {
      state.exportEdit = action.payload;
    },
    setBillEdit(state, action) {
      state.billEdit = action.payload;
    },
    setInvoiceEdit(state, action) {
      state.invoiceEdit = action.payload;
    },
    setInvoice(state, action) {
      // state.newInvoice = [...state.newInvoice, action.payload];
      const newInvoice = action.payload;
      state.newInvoice = newInvoice;
    },
    setShow(state, action) {
      state.show = action.payload;
    },
    setInvoiceNo(state, action) {
      state.invoiceNo = action.payload;
    },
    setBillNo(state, action) {
      state.billNo = action.payload;
    },
    setExportNo(state, action) {
      state.exportNo = action.payload;
    },
    setDeliveryNo(state, action) {
      state.deliveryNo = action.payload;
    },
    setCreditNo(state, action) {
      state.creditNo = action.payload;
    },
    setSalesOrderNo(state, action) {
      state.salesOrderNo = action.payload;
    },
    setPerformaNo(state, action) {
      state.performaNo = action.payload;
    },
    setEstimationNo(state, action) {
      state.estimationNo = action.payload;
    },
    setInventoryName(state, action) {
      state.inventoryName = action.payload;
    },
    setInvoicedata(state, action) {
      const invoiceData = action.payload;
      state.invoiceData = invoiceData;
    },
    setBilldata(state, action) {
      state.billData = action.payload;
    },
    setExportdata(state, action) {
      state.exportData = action.payload;
    },
    setDeliverydata(state, action) {
      state.deliveryData = action.payload;
    },
    setCreditdata(state, action) {
      state.creditData = action.payload;
    },
    setSalesOrderdata(state, action) {
      state.salesOrderData = action.payload;
    },
    setPerformadata(state, action) {
      state.performaData = action.payload;
    },
    setEstimationdata(state, action) {
      state.estimationData = action.payload;
    },
    setInvoiceGraph(state, action) {
      state.invoiceGraph = action.payload;
    },
    setBillGraph(state, action) {
      state.billGraph = action.payload;
    },
    setExportGraph(state, action) {
      state.exportGraph = action.payload;
    },
    setDeliveryGraph(state, action) {
      state.deliveryGraph = action.payload;
    },
    setCreditGraph(state, action) {
      state.creditGraph = action.payload;
    },
    setSalesOrderGraph(state, action) {
      state.salesOrderGraph = action.payload;
    },
    setPerformaGraph(state, action) {
      state.performaGraph = action.payload;
    },
    setEstimationGraph(state, action) {
      state.estimationGraph = action.payload;
    },
    setTermsCondition(state, action) {
      state.termsCondition = action.payload;
    },
    setBillTerms(state, action) {
      state.billTerms = action.payload;
    },
    setExportTerms(state, action) {
      state.exportTerms = action.payload;
    },
    setDeliveryTerms(state, action) {
      state.deliveryTerms = action.payload;
    },
    setCreditTerms(state, action) {
      state.deliveryTerms = action.payload;
    },
    setSalesOrderTerms(state, action) {
      state.salesOrderTerms = action.payload;
    },
    setPerformaTerms(state, action) {
      state.performaTerms = action.payload;
    },
    setEstimationTerms(state, action) {
      state.estimationTerms = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setInvoice,
  setShow,
  setInventoryName,
  setInvoicedata,
  setBilldata,
  setExportdata,
  setDeliverydata,
  setCreditdata,
  setSalesOrderdata,
  setPerformadata,
  setEstimationdata,
  setDebitdata,
  // All Sales Terms And Condition
  setTermsCondition,
  setBillTerms,
  setExportTerms,
  setDeliveryTerms,
  setCreditTerms,
  setSalesOrderTerms,
  setPerformaTerms,
  setEstimationTerms,
  setDebitTerms,
  // All Sales No
  setInvoiceNo,
  setBillNo,
  setExportNo,
  setDeliveryNo,
  setCreditNo,
  setSalesOrderNo,
  setPerformaNo,
  setEstimationNo,
  setDebitNo,
  // Sales All Graph
  setInvoiceGraph,
  setBillGraph,
  setExportGraph,
  setDeliveryGraph,
  setCreditGraph,
  setSalesOrderGraph,
  setPerformaGraph,
  setEstimationGraph,
  setDebitGraph,
  // sales edit
  setInvoiceEdit,
  setBillEdit,
  setExportEdit,
  setDeliveryEdit,
  setCreditEdit,
  setSalesOrderEdit,
  setPerformaEdit,
  setEstimationEdit,
  setDebitEdit,
} = slice.actions;

export const getDebitNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/DBN').then((response) => dispatch(setDebitNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebit = () => async (dispatch) => {
  try {
    await axios.get('/sales/debitAll').then((response) => dispatch(setDebitdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/DBN').then((response) => dispatch(setDebitGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/DBN').then((response) => dispatch(setDebitTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/debit?ID=${id}`).then((response) => dispatch(setDebitEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getInvoiceEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/invoice?ID=${id}`).then((response) => dispatch(setInvoiceEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getBillEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/billofsupply?ID=${id}`).then((response) => dispatch(setBillEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getExportEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/export?ID=${id}`).then((response) => dispatch(setExportEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};
export const getDeliveryEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/delivery?ID=${id}`).then((response) => dispatch(setDeliveryEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};
export const getCreditEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/credit?ID=${id}`).then((response) => dispatch(setCreditEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};
export const getSalesOrderEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/saleorder?ID=${id}`).then((response) => dispatch(setSalesOrderEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getPerformaEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/performainvoice?ID=${id}`).then((response) => dispatch(setPerformaEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getEstimationEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/estimation?ID=${id}`).then((response) => dispatch(setEstimationEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getTermsCondition = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/INV').then((response) => dispatch(setTermsCondition(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getBillTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/BOS').then((response) => dispatch(setBillTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExportTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/EXP').then((response) => dispatch(setExportTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getDeliveryTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/DCL').then((response) => dispatch(setDeliveryTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getCreditTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/CNT').then((response) => dispatch(setCreditTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOrderTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/SOD').then((response) => dispatch(setSalesOrderTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPerformaTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/PIV').then((response) => dispatch(setPerformaTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEstimationTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/ETB').then((response) => dispatch(setEstimationTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/INV').then((response) => dispatch(setInvoiceGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getBillGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/BOS').then((response) => dispatch(setBillGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExportGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/EXP').then((response) => dispatch(setExportGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getDeliveryGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/DCL').then((response) => dispatch(setDeliveryGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getCreditGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/CNT').then((response) => dispatch(setCreditGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOrderGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/SOD').then((response) => dispatch(setSalesOrderGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPerformaGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/PIV').then((response) => dispatch(setPerformaGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEstimationGraph = () => async (dispatch) => {
  try {
    await axios.get('/sales/summary/ETB').then((response) => dispatch(setEstimationGraph(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleCustomerName = () => async (dispatch) => {
  try {
    await axios.get('/sales/ledger').then((response) => dispatch(setInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/INV').then((response) => dispatch(setInvoiceNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getBillNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/BOS').then((response) => dispatch(setBillNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExportNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/EXP').then((response) => dispatch(setExportNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getDeliveryNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/DCL').then((response) => dispatch(setDeliveryNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCreditNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/CNT').then((response) => dispatch(setCreditNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOrderNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/SOD').then((response) => dispatch(setSalesOrderNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPerformaNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/PIV').then((response) => dispatch(setPerformaNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEstimationNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/ETB').then((response) => dispatch(setEstimationNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventoryName = () => async (dispatch) => {
  try {
    await axios.get('/sales/inventory').then((response) => dispatch(setInventoryName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoice = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceAll').then((response) => dispatch(setInvoicedata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBill = () => async (dispatch) => {
  try {
    await axios.get('/sales/billofsupplyAll').then((response) => dispatch(setBilldata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExport = () => async (dispatch) => {
  try {
    await axios.get('/sales/exportAll').then((response) => dispatch(setExportdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDelivery = () => async (dispatch) => {
  try {
    await axios.get('/sales/deliveryAll').then((response) => dispatch(setDeliverydata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCredit = () => async (dispatch) => {
  try {
    await axios.get('/sales/creditAll').then((response) => dispatch(setCreditdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSalesOrder = () => async (dispatch) => {
  try {
    await axios.get('/sales/saleorderAll').then((response) => dispatch(setSalesOrderdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPerformaInvoice = () => async (dispatch) => {
  try {
    await axios.get('/sales/performainvoiceAll').then((response) => dispatch(setPerformadata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getEstimation = () => async (dispatch) => {
  try {
    await axios.get('/sales/etimationAll').then((response) => dispatch(setEstimationdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
