export const officeQuestions = [
    {
      question: "Which of the following is essential for maintaining office records?",
      options: ["Filing", "Scanning", "Typing", "Printing"],
      answer: null
    },
    {
      question: "Which of the following is not office equipment?",
      options: ["Scanner", "Shredder", "Microwave", "Printer"],
      answer: null
    },
    {
      question: "What is the primary role of an office executive?",
      options: ["Data Entry", "Supporting administrative tasks", "Customer service", "Filing documents"],
      answer: null
    },
    {
      question: "What does 'minutes of the meeting' refer to?",
      options: ["The timing of the meeting", "A summary of what was discussed and decided", "Agenda of the next meeting", "None of the above"],
      answer: null
    },
    {
      question: "What is the correct way to handle confidential documents?",
      options: ["Shred them after use", "Leave them on the desk", "Share them with anyone", "File them in a shared folder"],
      answer: null
    },
    {
      question: "What does 'memo' stand for in office communication?",
      options: ["Memorandum", "Memory", "Memoir", "Memorial"],
      answer: null
    },
    {
      question: "What is the main purpose of archiving documents?",
      options: ["To delete old files", "To keep records for future reference", "To organize current documents", "To send documents to clients"],
      answer: null
    },
    {
      question: "Which software is commonly used for document creation in an office?",
      options: ["Microsoft Excel", "Microsoft Word", "Adobe Photoshop", "Microsoft PowerPoint"],
      answer: null
    },
    {
      question: "How often should an office executive back up important data?",
      options: ["Once a month", "Weekly", "Daily", "Only when required"],
      answer: null
    },
    {
      question: "What is the best way to prioritize tasks?",
      options: ["By importance and deadlines", "Randomly", "Based on what is easiest to complete", "Based on supervisor's preference"],
      answer: null
    },
    {
      question: "What is the primary channel for official communication in most offices?",
      options: ["Email", "Phone calls", "Social media", "Text messages"],
      answer: null
    },
    {
      question: "Which is the most professional way to start an email?",
      options: ["Hey!", "Dear Mr./Ms.", "Hi there!", "Yo!"],
      answer: null
    },
    {
      question: "When answering the phone in a professional setting, what is the correct way to greet?",
      options: ["Hello, who is this?", "Good morning, [Company Name], how can I assist you?", "What's up?", "Just a moment!"],
      answer: null
    },
    {
      question: "Which of the following is not a good communication practice?",
      options: ["Active listening", "Interrupting frequently", "Asking questions", "Maintaining eye contact"],
      answer: null
    },
    {
      question: "What should be included in the subject line of a professional email?",
      options: ["A greeting", "The purpose of the email", "Personal information", "None of the above"],
      answer: null
    },
    {
      question: "What does 'cc' in an email stand for?",
      options: ["Clear copy", "Carbon copy", "Central copy", "Copy confirmation"],
      answer: null
    },
    {
      question: "Which communication skill involves understanding the speaker's emotions and thoughts?",
      options: ["Speaking clearly", "Listening actively", "Writing concisely", "Reading fluently"],
      answer: null
    },
    {
      question: "What is the best way to respond to a negative email?",
      options: ["Ignore it", "Respond professionally and calmly", "Reply with anger", "Forward it to others"],
      answer: null
    },
    {
      question: "When writing a formal letter, how should you close it?",
      options: ["Best regards", "Cheers", "See you later", "Take care"],
      answer: null
    },
    {
      question: "What is the most effective form of communication in a crisis?",
      options: ["Email", "Face-to-face communication", "Social media", "Phone calls"],
      answer: null
    },
    {
      question: "Which of the following is used for creating spreadsheets?",
      options: ["Microsoft Word", "Microsoft Excel", "Google Docs", "Adobe Acrobat"],
      answer: null
    },
    {
      question: "Which key combination is used to copy text in most applications?",
      options: ["Ctrl + X", "Ctrl + C", "Ctrl + V", "Ctrl + A"],
      answer: null
    },
    {
      question: "Which application is used to manage emails?",
      options: ["Microsoft Word", "Adobe Reader", "Microsoft Outlook", "PowerPoint"],
      answer: null
    },
    {
      question: "What does the shortcut `Ctrl + Z` do?",
      options: ["Save the document", "Redo an action", "Undo an action", "Close the document"],
      answer: null
    },
    {
      question: "Which application is commonly used for presentations?",
      options: ["Microsoft Excel", "Microsoft PowerPoint", "Adobe Illustrator", "Microsoft Access"],
      answer: null
    },
    {
      question: "What type of software is Microsoft Access?",
      options: ["Spreadsheet", "Word processor", "Database management", "Presentation software"],
      answer: null
    },
    {
      question: "Which file format is commonly used for documents shared via email?",
      options: [".docx", ".pdf", ".pptx", ".xlsx"],
      answer: null
    },
    {
      question: "In Excel, what does the `SUM` function do?",
      options: ["Adds all the numbers in a range of cells", "Finds the average of a range of cells", "Subtracts values", "Displays text"],
      answer: null
    },
    {
      question: "What is cloud storage?",
      options: ["Storage space on a local computer", "Storage space on external hard drives", "Storage space on the internet", "A type of physical filing system"],
      answer: null
    },
    {
      question: "Which of the following is an online collaboration tool?",
      options: ["Google Drive", "Notepad", "Microsoft Word", "Paint"],
      answer: null
    }

  ];
  