export const hradminquestion = [
    {
      question: 'What does HR stand for?',
      options: [
        'Human Resources',
        'Human Relations',
        'Human Rights',
        'Human Recruitment'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is a primary function of HR?',
      options: [
        'Sales management',
        'Recruitment and selection',
        'Product development',
        'Marketing strategy'
      ],
      answer: ''
    },
    {
      question: 'What is the primary purpose of performance appraisals?',
      options: [
        'To increase salaries',
        'To evaluate employee performance',
        'To terminate employees',
        'To hire new staff'
      ],
      answer: ''
    },
    {
      question: 'Which law regulates the minimum wage in the workplace?',
      options: [
        'Fair Labor Standards Act',
        'Occupational Safety and Health Act',
        'Family and Medical Leave Act',
        'Equal Employment Opportunity Act'
      ],
      answer: ''
    },
    {
      question: 'What is the term for the process of identifying and attracting potential candidates for a job?',
      options: [
        'Onboarding',
        'Recruitment',
        'Retention',
        'Orientation'
      ],
      answer: ''
    },
    {
      question: 'What is the primary purpose of a job description?',
      options: [
        'To list employee duties',
        'To outline salary information',
        'To describe company culture',
        'To promote the company'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is a common method of interviewing?',
      options: [
        'Group interview',
        'Panel interview',
        'Phone interview',
        'All of the above'
      ],
      answer: ''
    },
    {
      question: 'Which assessment tool is commonly used to evaluate a candidate\'s personality fit for a role?',
      options: [
        'Skills test',
        'IQ test',
        'Personality test',
        'Performance review'
      ],
      answer: ''
    },
    {
      question: 'What does the term "cultural fit" refer to?',
      options: [
        'The candidate\'s skills',
        'The alignment between a candidate’s values and the company’s culture',
        'The candidate\'s education',
        'The candidate\'s experience'
      ],
      answer: ''
    },
    {
      question: 'What is an Applicant Tracking System (ATS)?',
      options: [
        'A software for managing employee records',
        'A tool for tracking employee performance',
        'A system for managing job applications',
        'A system for training employees'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is an example of employee engagement?',
      options: [
        'High turnover rate',
        'Low productivity',
        'Employees taking ownership of their work',
        'Frequent absences'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of an employee handbook?',
      options: [
        'To provide job descriptions',
        'To outline company policies and procedures',
        'To list employee benefits',
        'To detail performance metrics'
      ],
      answer: ''
    },
    {
      question: 'What does "conflict resolution" refer to?',
      options: [
        'Ignoring disagreements',
        'Finding a mutually acceptable solution to a disagreement',
        'Terminating employees',
        'Reprimanding staff'
      ],
      answer: ''
    },
    {
      question: 'What is the significance of exit interviews?',
      options: [
        'To gather feedback from departing employees',
        'To enforce company policies',
        'To celebrate employee achievements',
        'To hire new staff'
      ],
      answer: ''
    },
    {
      question: 'What is the key goal of employee retention strategies?',
      options: [
        'To reduce hiring costs',
        'To increase employee turnover',
        'To improve employee engagement and satisfaction',
        'To promote competition among employees'
      ],
      answer: ''
    },
    {
      question: 'Which act requires employers to provide unpaid leave for certain family and medical reasons?',
      options: [
        'Americans with Disabilities Act',
        'Fair Labor Standards Act',
        'Family and Medical Leave Act',
        'Title VII of the Civil Rights Act'
      ],
      answer: ''
    },
    {
      question: 'What does the acronym FLSA stand for?',
      options: [
        'Family Leave and Safety Act',
        'Fair Labor Standards Act',
        'Federal Labor Standards Association',
        'Fair Leave and Safety Act'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of OSHA?',
      options: [
        'To regulate wages',
        'To ensure workplace safety',
        'To oversee hiring practices',
        'To monitor employee benefits'
      ],
      answer: ''
    },
    {
      question: 'Which law prohibits discrimination in employment based on race, color, religion, sex, or national origin?',
      options: [
        'Age Discrimination in Employment Act',
        'Equal Pay Act',
        'Civil Rights Act',
        'Family Medical Leave Act'
      ],
      answer: ''
    },
    {
      question: 'What is considered a violation of workplace harassment policies?',
      options: [
        'Friendly teasing among coworkers',
        'Inappropriate comments or actions based on a protected characteristic',
        'Offering constructive criticism',
        'Regular team meetings'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of employee training?',
      options: [
        'To fulfill legal requirements',
        'To improve employee skills and performance',
        'To increase employee turnover',
        'To reduce workload'
      ],
      answer: ''
    },
    {
      question: 'Which method is commonly used for on-the-job training?',
      options: [
        'E-learning',
        'Job shadowing',
        'Classroom instruction',
        'Workshops'
      ],
      answer: ''
    },
    {
      question: 'What is a mentorship program?',
      options: [
        'A program for hiring new employees',
        'A formal arrangement for experienced employees to guide less experienced employees',
        'A training program for technical skills',
        'A performance evaluation process'
      ],
      answer: ''
    },
    {
      question: 'What is the primary benefit of employee development programs?',
      options: [
        'Increased employee turnover',
        'Enhanced employee skills and satisfaction',
        'Lower training costs',
        'Reduced hiring needs'
      ],
      answer: ''
    },
    {
      question: 'What is a key component of an effective onboarding process?',
      options: [
        'Only introducing new hires to their direct supervisors',
        'Providing comprehensive company information and support',
        'Completing paperwork quickly',
        'Ignoring company culture'
      ],
      answer: ''
    },
    {
      question: 'What is the main goal of performance management?',
      options: [
        'To punish underperforming employees',
        'To align individual performance with organizational goals',
        'To create competition among employees',
        'To increase employee workloads'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is a common performance appraisal method?',
      options: [
        '360-degree feedback',
        'Random selection',
        'Anonymous surveys',
        'Self-assessment only'
      ],
      answer: ''
    },
    {
      question: 'What does the term “SMART” in goal setting stand for?',
      options: [
        'Specific Measurable Achievable Relevant Time-bound',
        'Simple Manageable Accessible Realistic Timely',
        'Strategic Motivational Accurate Relevant Testable',
        'Specific Meaningful Achievable Relevant Timed'
      ],
      answer: ''
    },
    {
      question: 'What is a potential downside of using numerical ratings in performance appraisals?',
      options: [
        'They provide clear feedback',
        'They may overlook qualitative aspects of performance',
        'They are easy to implement',
        'They promote consistency'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of setting KPIs (Key Performance Indicators)?',
      options: [
        'To measure employee attendance',
        'To evaluate the performance of individuals and teams',
        'To determine salary increases',
        'To track training completion'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of a salary survey?',
      options: [
        'To determine employee training needs',
        'To compare company salaries to the market',
        'To set performance goals',
        'To evaluate employee satisfaction'
      ],
      answer: ''
    },
    {
      question: 'What does “total compensation” include?',
      options: [
        'Only salary',
        'Salary plus benefits and bonuses',
        'Only bonuses',
        'Salary minus taxes'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is considered a non-monetary benefit?',
      options: [
        'Health insurance',
        'Retirement plan',
        'Flexible work hours',
        'Salary'
      ],
      answer: ''
    },
    {
      question: 'What is a “pay equity” analysis?',
      options: [
        'Comparing salaries of employees in different companies',
        'Ensuring that employees are paid fairly regardless of gender or race',
        'Determining the salary of new hires',
        'Analyzing benefits packages'
      ],
      answer: ''
    },
    {
      question: 'What is the role of a compensation committee?',
      options: [
        'To oversee employee training',
        'To evaluate and recommend compensation policies',
        'To handle recruitment',
        'To manage employee relations'
      ],
      answer: ''
    },
    {
      question: 'What is the primary goal of a workplace diversity policy?',
      options: [
        'To reduce hiring costs',
        'To promote an inclusive workplace',
        'To increase employee turnover',
        'To focus on one demographic group'
      ],
      answer: ''
    },
    {
      question: 'Which of the following is a common workplace policy?',
      options: [
        'Dress code',
        'Employee appreciation',
        'Open-door policy',
        'All of the above'
      ],
      answer: ''
    },
    {
      question: 'What does a telecommuting policy typically cover?',
      options: [
        'Employee training needs',
        'Guidelines for remote work arrangements',
        'Health and safety regulations',
        'Recruitment strategies'
      ],
      answer: ''
    },
    {
      question: 'What is the purpose of a conflict of interest policy?',
      options: [
        'To promote employee engagement',
        'To outline expectations regarding personal relationships',
        'To ensure fair hiring practices',
        'To determine performance metrics'
      ],
      answer: ''
    },
  ];
  